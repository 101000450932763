<template>

    <div class="bigBox">
        <div class="wdxmTopBox">
            <img src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/Icon(1)_1686298472325.png" class="backIcon" @click="toLink('grzx')">
            <p class="wdxmTopBoxTitle">我的项目</p>
        </div>
        <div style="height:.88rem"></div>

        <div class="wdxmMenuBox">
            <div class="wdxmMenuItem wdxmMenuItemActive" @click="getXmFrom(1)">全部</div>
            <div class="wdxmMenuItem" @click="getXmFrom(2)">已完成</div>
            <div class="wdxmMenuItem" @click="getXmFrom(3)">进行中</div>
            <div class="wdxmMenuItem" @click="getXmFrom(4)">已取消</div>
            <div class="wdxmMenuXian"></div>
        </div>

        <div style="height:.84rem"></div>

        <div class="wdxmList" v-if="moviefrom[0]">

            <div class="wdxmItem" v-for="(item) in moviefrom" :key="item.orderId">
                <router-link :to="{path:`/xmxq/${item.orderId}`}">

                <div class="wdxmItemTopBox">
                    <p class="wdxmItemTopBoxId">合同编号：{{item.orderCode}}</p>
                    <div class="wdxmItemTopBoxType">
                        <p >{{item.delistedOrderStatus_dictText}}</p>
                        <img v-if="item.delistedOrderStatus == 1" src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/路径409_1686549956588.png">
                        <img v-else-if="item.delistedOrderStatus == 0" src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/路径457_1686551388511.png">
                        <img v-else-if="item.delistedOrderStatus == 2" src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/路径458_1686551407538.png">

                    </div>
                </div>

                <div class="wdxmItemBottomBox">
                    <img :src="item.squarePic" class="wdxmItemBottomBoxMovieImg">
                    <div class="wdxmItemXxBox">
                        <p class="wdxmItemMovieName">{{item.movie}}</p>
                        <div class="wdxmItemMovieTimeBox">
                            <img src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/组731_1686551182807.png" class="wdxmItemMovieTimeImg">
                            <p class="wdxmItemMovieTime">{{item.delistedTime}}</p>
                        </div>
                    </div>
                    <div class="wdxmItemMovieNumBox">
                        <span class="wdxmItemMovieNumBoxSpan1">金额</span>
                        <span class="wdxmItemMovieNumBoxSpan2">¥{{item.orderAmount}}</span>
                    </div>
                </div>

                </router-link>
            </div>


        </div>

        <div v-else class="noData">
            暂无项目
        </div>



    </div>



</template>

<script>
import Cookies from 'js-cookie'
import {getListColumn,getChildColumn,getArticleAndProject,getListBanner,getlistOnHomepage,getlistOnHomepage2,sms,phoneRegister,listMovieDelistedOrder} from "@/api/index"
import { Loading ,Message } from 'element-ui';
import store from '@/store'

export default {

    data(){
        return {
            load : null,
            moviefrom: [
            ],
            wjsToken: null,
            wjsUserInfo: null,
            currPageNo:1,
            pageSize:8,
            total:0,
            movieType:0,
            t : null,
            clientHeight : null,
            scrollHeight : null,
            movieload:true,
        }
    },
    methods:{
        toLink(name){
            // if(name == "yszb"){
            //     //window.location.href="http://www.zygart.cn"; 
            // }else{
                //this.reset()
                this.$router.push({name:name});
            //}
        },
        showLoading(){
            this.load && this.load.close(); 
            this.load = Loading.service({
                lock: true,
                text: '加载中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
        },

        toBack(){
            this.$router.back(-1)
        },

        getXmFrom(index){
            for(let i = 0;i < 4;i++){
                document.getElementsByClassName('wdxmMenuItem')[i].classList.remove('wdxmMenuItemActive');
            }
            if(index == 1){
                $('.wdxmMenuXian').css('left','calc(.22rem + .05rem)');
                document.getElementsByClassName('wdxmMenuItem')[0].classList.add('wdxmMenuItemActive');
                this.movieType = 0;
            }else if(index == 2){
                $('.wdxmMenuXian').css('left','calc(.22rem + .05rem + 1.04rem)');
                document.getElementsByClassName('wdxmMenuItem')[1].classList.add('wdxmMenuItemActive');
                this.movieType = 2;
            }else if(index == 3){
                $('.wdxmMenuXian').css('left','calc(.22rem + .05rem + 2.22rem)');
                document.getElementsByClassName('wdxmMenuItem')[2].classList.add('wdxmMenuItemActive');
                this.movieType = 1;
            }else if(index == 4){
                $('.wdxmMenuXian').css('left','calc(.22rem + .05rem + 3.42rem)');
                document.getElementsByClassName('wdxmMenuItem')[3].classList.add('wdxmMenuItemActive');
                this.movieType = 3;
            }
            this.getListMovieDelistedOrder(this.movieType);


        },
        getListMovieDelistedOrder(index){
            //this.showLoading()

            if(index){
                listMovieDelistedOrder({
                    "data": {
                        "delistedOrderStatus": index - 1
                    },
                    "page": {
                        "currPageNo": this.currPageNo,
                        "pageSize": this.pageSize
                    }
                }).then(res => {
                    console.log(res)
                    this.load && this.load.close();
                    if(res.code == 200){
                        this.moviefrom = res.result.records;
                        this.total = res.result.records;
                    }else{
                        Message.error({
                            message: res.message,
                            center: true,
                            duration:2000,
                            showClose: false,
                        })
                    }
                })
            }else{
                listMovieDelistedOrder({
                    "data": {

                    },
                    "page": {
                        "currPageNo": this.currPageNo,
                        "pageSize": this.pageSize
                    }
                }).then(res => {
                    console.log(res)
                    this.load && this.load.close();
                    if(res.code == 200){
                        this.moviefrom = res.result.records;
                        this.total = res.result.records;
                    }else{
                        Message.error({
                            message: res.message,
                            center: true,
                            duration:2000,
                            showClose: false,
                        })
                    }
                })
            }
            
        },
        windowscroll(){
            this.t = document.documentElement.scrollTop || document.body.scrollTop;
            this.clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
            this.scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;

            if(this.scrollHeight > this.clientHeight && this.t + this.clientHeight + 200  >= this.scrollHeight) {
                if(this.movieload && this.moviefrom.length && this.moviefrom.length < this.total){
                            this.movieload = false;
                            this.currPageNo += 1;
                            this.getListMovieDelistedOrder(this.movieType);

                            setTimeout(() => {
                                this.movieload = true;
                            },3000)

                    
                }
                
            }
        },

            

    },
    mounted(){

        this.wjsToken =  Cookies.get('wjsToken') || null;
        this.wjsUserInfo =  Cookies.get('wjsUserInfo') || null;
        this.wjsUserInfo = JSON.parse(this.wjsUserInfo);

        console.log(this.wjsToken)
        console.log(this.wjsUserInfo)


        this.t = document.documentElement.scrollTop || document.body.scrollTop;
        this.clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
        this.scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
        window.addEventListener('scroll',this.windowscroll)

        

        this.getListMovieDelistedOrder(this.movieType);


    },
    filters: {
        movieTypeFilters: function (value) {
            if (!value) return ''
                switch(value){
                case 1:
                    return "院线电影"
                    break;
                case 2:
                    return "网络电影"
                    break;
                default:
                    return "error"
            }
        },
        shootStatusFilters: function (value) {
            if (!value) return ''
                switch(value){
                case 1:
                    return "未上映"
                    break;
                case 2:
                    return "已上映"
                    break;
                default:
                    return "error"
            }
        },
        xmTypeFilters: function (value) {
            //if (!value) return ''
            switch(value){
                case 0:
                    return "已完成"
                    break;
                case 1:
                    return "进行中"
                    break;
                case 2:
                    return "已取消"
                    break;
                default:
                    return ""
            }
        },
        dataFilter:function(val){
            if (!val) return ''
            return val.substring(0,10)
        }
    },

}




</script>





<style lang="scss" scoped>
.bigBox{
    width: 100%;
    height: 100%;
    background-color: #fff;

    .wdxmTopBox{
        height: .88rem;
        width: 100%;
        position: relative;
        position: fixed;
        top: 0;
        z-index: 999;
        background-color: #fff;
        .backIcon{
            height: .48rem;
            width: .48rem;
            position: absolute;
            top: .2rem;
            left: .4rem;
        }
        .wdxmTopBoxTitle{
            font-size: .34rem;
            color: #343434;
            text-align: center;
            line-height: 0.88rem;
        }
    }
    .wdxmMenuBox{
        height: .84rem;
        width: 100%;
        position: fixed;
        top: .88rem;
        z-index: 999;
        background-color: #fff;
        padding: 0 .22rem;
        box-sizing: border-box;
        display: flex;
        justify-content: left;
        .wdxmMenuItem{
            font-size: .28rem;
            text-align: center;
            line-height: .84rem;
            color: #343434;
            margin-right: .34rem;
        }
        .wdxmMenuItem:last-child{
            margin-right: 0;
        }
        .wdxmMenuItemActive{
            color: #A30001;

        }
        .wdxmMenuXian{
            height: .04rem;
            width: .46rem;
            position: absolute;
            bottom: 0;
            left: calc(.22rem + .05rem);
            background: #A30001;
            border-radius: 2.4rem;
            transition: .5s;
        }
    }

    .wdxmList{
        background: #F6F6F6;
        padding: .1rem .22rem;
        box-sizing: border-box;
        position: relative;
        overflow: hidden;
        width: 100%;
        min-height: calc(100% - 0.88rem);
        .wdxmItem{
            width: 100%;
            background: #FFFFFF;
            border-radius: .08rem;
            padding: .22rem;
            box-sizing: border-box;
            position: relative;
            overflow: hidden;
            margin-bottom: .1rem;
            .wdxmItemTopBox{
                display: flex;
                justify-content: space-between;
                overflow: hidden;
                align-items: center;
                margin-bottom: .26rem;

                .wdxmItemTopBoxId{
                    font-size: .24rem;
                    color: #999999;
                    white-space: nowrap;
                }
                .wdxmItemTopBoxType{
                    position: relative;
                    font-size: .24rem;
                    color: #FFFFFF;
                    width: 1.2rem;
                    height: .38rem;
                    text-align: center;
                    line-height: .38rem;
                    z-index: 2;
                    p{
                        position: relative;
                        z-index: 2;
                    }
                    img{
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        z-index: 1;
                        object-fit: cover;
                        inset: 0;
                        margin: auto;
                    }
                }


            }

            .wdxmItemBottomBox{
                display: flex;
                justify-content: space-between;
                overflow: hidden;
                position: relative;
                .wdxmItemBottomBoxMovieImg{
                    width: 1.56rem;
                    height: 1.78rem;
                    object-fit: cover;
                    margin-right: .22rem;
                }
                .wdxmItemXxBox{
                    width: calc(100% - 1.78rem);
                    .wdxmItemMovieName{
                        font-size: .32rem;
                        color: #2D2D2F;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        margin-bottom: .2rem;
                    }
                    .wdxmItemMovieTimeBox{
                        display: flex;
                        align-items: center;
                        .wdxmItemMovieTimeImg{
                            width: .22rem;
                            height: .22rem;
                            object-fit: cover;
                            margin-right: .12rem;
                        }
                        .wdxmItemMovieTime{
                            font-size: .24rem;
                            color: #999999;
                        }
                    }
                }
                .wdxmItemMovieNumBox{
                    position: absolute;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    right: 0;
                    bottom: 0;
                    height: .48rem;
                    display: flex;
                    .wdxmItemMovieNumBoxSpan1{
                        font-size: .34rem;
                        color: #999999;
                        margin-right: .04rem;
                    }
                    .wdxmItemMovieNumBoxSpan2{
                        font-size: .34rem;
                        font-family: PingFang SC-Bold, PingFang SC;
                        font-weight: bold;
                        color: #CC5549;
                    }

                }
            }





        }
        .wdxmItem:last-child{
            margin-bottom: 0;
        }



    }





}

.noData{
    font-size: .4rem;
    text-align: center;
    line-height: 3rem;
}


</style>